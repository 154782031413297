@media screen and (max-width: 1900px) {
    .login-container {
        height: 300px;
        padding: 10px 20px;
    }

    .login-form .login-title {
        font-size: 25px;
    }

    .login-form .ant-input {
        font-size: 18px;
    }

    .login-form .login-form-button {
        font-size: 20px;
    }

    .login-header-content .right-part {
        font-size: 25px;
    }

    .dropdown-list ul li {
        font-size: 18px;
    }

    .dashboard-container .dashboard-top-summary {
        height: auto;
        padding-bottom: 30px;
    }

    .event-clip {
        width: 33.33%;
    }

    .dashboard-report .report-item {
        height: auto;
    }

    .dashboard-map {
        margin-top: 10px
    }

    .ant-layout-header .menu {
        position: absolute;
        left: 20px;
        line-height: 80px;
    }

    .gpsbd .header-right {
        position: absolute;
        right: 0;
        float: none;
    }

    .tblNoHeader .row40 {
        min-height: 40px;
        height: auto;
    }

    .map-buttons button, .row40 .ant-col {
        line-height: 40px;
        height: auto;
    }

    .row-mobile .ant-col:first-child {
        width: 50%;
    }

    .row-mobile .ant-col:nth-child(2n) {
        width: 50%;
    }

    .row-mobile .ant-col:last-child {
        width: 100%;
    }

    .trip-desc {
        height: auto;
    }

    .video-container {
        height: calc(100% - 230px);
        width: calc(100% - 80px);
        position: absolute
    }

    .speed-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: 0px;
    }

    .networkstate-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -200px
    }

    .rssi-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -400px
    }

    .plmn-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -600px
    }

    .tripvideo-speedchart{
        width:100%;
        margin:20%;
        display:inline;
    }

    .list-containe .search-box {
        width: 560px;
    }
}

@media screen and (max-width: 1199px) {
    .event-clip {
        width: 50%;
    }

    .dashboard-report {
        flex-wrap: wrap;
    }

    .dashboard-report .report-item {
        width: calc(50% - 15px)
    }

    .dashboard-report .report-item:last-child {
        margin-top: 20px;
        width: 100%;
    }

    .login-header-content {
        max-width: 100%;
    }

    .login-header-content .right-part {
        font-size: 24px;
    }

    .dropdown-list ul li {
        font-size: 16px;
    }

    .list-containe,
    .list-button-mobile {
        margin-top: 20px;
    }

    .list-containe .search-box {
        width: 100%;
    }

    .trip-desc {
        height: auto;
    }

    .trip-drive-info {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
    }

    .video-container {
        height: calc(100% - 230px);
        width: calc(100% - 80px);
        position: absolute
    }

    .speed-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: 0px;
    }

    .networkstate-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -200px
    }

    .rssi-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -400px
    }

    .plmn-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 80px);
        bottom: -600px
    }

    .list-header.no-search {
        margin-bottom: 30px;
    }

    .gpsbd .header-right {
        top: 0px;
    }

    .dropdown-list {
        width: 100%;
    }

    /* mobile菜单 */
    .ant-drawer-body {
        font-size: inherit;
        padding: 20px 0;
    }

    .customize-menus {
        background: #fff;
        font-size: 16px;
        line-height: 30px;
        list-style: none;
        padding-inline-start: 0px;
        margin-bottom: 0;
    }

    .customize-menus ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }

    .customize-menus .menu-title {
        display: block;
        height: 30px;
        line-height: 30px;
        overflow: hidden;
        padding-right: 20px;
        word-wrap: break-word;
    }

    .menu-title.selected,
    .customize-menus .menu-title:hover {
        background: #008494;
        color: #fff;
    }

    .level-0 .menu-title {
        padding-left: 20px;
    }

    .level-1 .menu-title {
        padding-left: 40px;
    }

    .level-2 .menu-title {
        padding-left: 60px
    }
}

@media screen and (max-width: 800px) {
    html {
        font-size: 16px;
    }

    .tripvideo-speedchart{
        width:100%;
        margin:0%;
        display:inline-flex;
        font-size: 5px;
    }

    /* body{
      font-size: 16px !important;
    } */
    .login-header {
        height: 6.875rem;
    }

    .login-header-content .logo {
        margin-top: 1.25rem;
        margin-left: 3.375rem;
        height: 4.375rem;
    }

    .mobile-right-part {
        float: right;
        width: 50%;
        height: 6.875rem;
        line-height: 6.875rem;
    }

    .mobile-right-part a,
    .mobile-right-part a:active,
    .mobile-right-part a:hover,
    .mobile-right-part a:focus {
        color: #fff;
    }

    .mobile-right-part .menu {
        float: right;
        margin-right: 1.875rem;
    }

    .mobile-right-part .menu img {
        height: 1em;
    }

    .ant-drawer-close {
        display: none !important;
    }

    .mobile-list-menu {
        padding: 0 1.5rem;
        margin: 0px;
        list-style: none;
    }

    .mobile-list-menu li {
        height: 5.625rem;
        line-height: 5.625rem;
        font-size: 1.875rem;
        color: #fff;
    }

    .mobile-list-menu li.selected {
        color: #073f56
    }

    /* login */
    .login-bg {
        top: 6.875rem;
        height: calc(100% - 6.875rem)
    }

    .login-draw .ant-drawer-content {
        background: #378fb2;
    }

    .login-item{
        height: 25px;
        fontSize: 20px;
        lineHeight: 25px;
        textAlign: left;
        width: 100%;
    }

    .login-container {
        height: 38.25rem;
        background: #fff;
        padding: 0;
    }

    .login-form .login-title {
        font-size: 3rem;
        margin-bottom: 3.125rem;
    }

    .login-form .ant-input {
        height: 5rem;
        font-size: 1.75rem;
    }

    .login-form .login-form-button {
        height: 4.625rem;
        font-size: 1.75rem;
    }

    .login-form .ant-input {
        padding-left: 2.5rem;
        padding-right: 4.375rem;
    }

    .ant-modal-wrap.register-mask {
        top: 6.875rem;
    }

    .register-mask .ant-modal {
        margin: 2rem;
        max-width: calc(100vw - 4rem)
    }

    .login-form .login-form-button_remembered{
        background: #166a8c;
        width:100%;
        font-size: 20px;
        color:#fff;
    }

    .find-pass-container {
        background: #fff;
        padding: 0;
        top: calc(50% - 14.125rem);
    }

    .register-container {
        padding: 0;
    }

    .register-container .ant-form a, .register-container .ant-form label {
        font-size: 1.75rem;
    }


    .ant-layout.ant-layout-has-sider {
        flex-direction: column !important;
    }

    .ant-layout-sider {
        position: absolute !important;
        flex: none !important;
        z-index: 1;
    }

    /* mobile菜单 */
    .ant-drawer-body {
        font-size: inherit;
        padding: 3rem 0;
    }

    .customize-menus {
        background: #fff;
        font-size: 1.75rem;
        line-height: 3.75rem;
        list-style: none;
        padding-inline-start: 0px;
        margin-bottom: 0;
    }

    .customize-menus ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }

    .customize-menus .menu-title {
        display: block;
        height: 3.75rem;
        line-height: 3.75rem;
        overflow: hidden;
        padding-right: 1.25rem;
        word-wrap: break-word;
    }

    .menu-title.selected,
    .customize-menus .menu-title:hover {
        background: #008494;
        color: #fff;
    }

    .level-0 .menu-title {
        padding-left: 1.25rem;
    }

    .level-1 .menu-title {
        padding-left: 2.5rem;
    }

    .level-2 .menu-title {
        padding-left: 3.75rem;
    }


    /* backend header */
    .header-logo {
        position: absolute;
        left: calc(50% - 5.6875rem);
        top: 0px;
        z-index: 1;
        width: 11.375rem;
    }

    .header-logo img {
        width: 11.375rem;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
    }

    .header-logo, .gpsbd .ant-layout-header {
        height: 6.875rem;
    }

    .ant-layout-header .menu {
        display: inline-block;
        left: 1.875rem;
    }

    .ant-layout-header .menu img {
        width: 2.25rem;
    }

    .gpsbd .header-right {
        height: 6.875rem;
    }

    .header-right .change-language {
        height: 6.875rem;
        line-height: 6.875rem;
        background-size: 2rem 2rem;
    }

    .header-right .header-drop-down {
        height: 6.875rem;
        margin-right: 3.125rem;
        margin-left: 1.125rem;
        z-index: 1001;
    }

    .header-right .dropdown-list {
        top: 6.875rem;
    }

    .dropdown-list {
        width: 100%;
    }

    .dropdown-list ul li {
        padding-left: 0;
        text-align: center;
    }

    /* dashboard */
    .event-clip .item-content {
        margin-top: 0px;
    }

    .dashboard-container .dashboard-top-summary {
        background: none;
        padding-left: 0;
        height: auto;
        padding-bottom: 0;
    }

    .event-clip .img-container {
        margin-top: 0px;
    }

    .event-clip {
        width: 50%;
        margin-bottom: 1.25rem;
        height: auto;
    }

    .sub-container {
        margin-right: 0.9375rem;
        background: #fff;
        border-radius: 0.625rem;
        padding: 1.875rem 1.25rem;
    }

    .event-clip .item-content {
        min-height: 8.75rem;
        padding-left: 9.375rem;
        height: auto;
    }

    .event-clip .item-content .span-top {
        height: 2.5rem;
        line-height: 2.5rem;
        font-size: 2.5rem;
        margin-bottom: 0;

    }

    .event-clip .item-content .span-bottom {
        font-size: 1.75rem;
        margin-top: 0.25rem;
        height: 5rem;
        line-height: 2.5rem;
        overflow: hidden;
        display: block;
        word-break: break-all;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .report-item-info img {
        width: 100%;
    }

    .dashboard-report {
        margin-bottom: 1.25rem;
        margin-top: 0px;
        flex-direction: column;
    }

    .dashboard-report::after {
        clear: 'both';
        content: '';
        height: 0.625rem;
        display: block;
    }

    .dashboard-report .report-item {
        width: 100%;
        height: auto;
        float: left;
        margin-bottom: 1.25rem;
        background: none;
    }

    .report-item .report-item-title {
        font-size: 2.625rem;
        margin-bottom: 0px;
    }

    .report-item-info {
        background: #fff;
    }

    .dashboard-map {
        margin-top: 0;
        height: 31.25rem;
    }

    /* vehicle list */
    .list-containe {
        padding: 1.75rem;
        margin-top: 1.75rem;
    }

    .list-search {
        padding: 0;
        margin-bottom: 1.75rem;
    }

    .list-search .list-button-mobile {
        margin-top: 1.75rem;
        text-align: right;
    }

    .row-mobile {
        margin-bottom: 1.875rem;
    }

    .tblNoHeader .row40 {
        min-height: 4.75rem;
        height: auto;
    }

    .map-buttons button, .row40 .ant-col {
        line-height: 4.75rem;
        font-size: 1.75rem;
        height: auto;
    }

    .action-common-box {
        height: 5rem;
        width: 5rem;
    }

    /* trip video */
    .trip-desc {
        line-height: 3.75rem;
        height: auto;
        margin-bottom: 1.75rem;
        font-size: 2rem;
        padding: 1.75rem;
    }

    .trip-desc-title {
        font-size: 2.25rem;
    }

    .back-link {
        font-size: 1.75rem;
        margin-left: 1.75rem;
    }

    .row-end {
        text-align: left;
        padding-left: 50px;
        padding-right: 0;
        background-position: left center;
    }

    .trip-drive-info {
        border-top: 1px solid #dedede;
        border-bottom: 1px solid #dedede;
    }

    .trip-data-right{
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex:1
    }
    .trip-data-left{
        flex:0.5
    }


    .replay_geofence{
        position: absolute;
        left:10px;
        bottom:180px;
        z-index: 999;
        background: #fff;
        width:52px;
        height:52px;
        padding:10px;
        border:1px solid #dedede;
        opacity: 0.7;
    }

    .replay_event{
        position: absolute;
        left:62px;
        bottom:130px;
        z-index: 999;
        background: #fff;
        width:52px;
        height:52px;
        padding:10px;
        border:1px solid #dedede;
        opacity: 0.7;
    }

    .process-bar{
        background: #ededed;
        position: absolute;
        flex: 1;
        left:0;
        top:0px;
        right:5px;
        height: 5px;
        width: calc(100% - 10px);
    }

    .video-container {
        height: calc(100% - 230px);
        width: calc(100% - 20px);
        position: absolute
    }

    .speed-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 20px);
        bottom: 0px;
    }

    .networkstate-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 20px);
        bottom: -200px
    }

    .rssi-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 20px);
        bottom: -400px
    }

    .plmn-chart-container {
        background: #fff;
        position: absolute;
        width: calc(100% - 20px);
        bottom: -600px
    }

    .map-buttons {
        padding: 1.25rem 2.5rem;
        font-size: 1.75rem;
    }

    /* device list */
    .list-containe .search-box {
        width: 100%;
    }

    .list-containe .list-header {
        font-size: 2.5rem;
        padding-left: 3.75rem;
        margin-bottom: 1.75rem;
    }

    .map-selector{
        z-index: 1000;
    }

    .tripvideo-endTime {
        top: 75px;
        left: 50px;
        right: initial
    }

    .tripvideo-scale {
        top: 120px
    }

    .back_to_location {
        bottom: 130px;
    }

    .tripvideo-mile,
    .tripvideo-drivingTime {
        bottom: 70px;
    }
}

@media screen and (max-width: 414px) {
    html {
        font-size: 8.832px;
    }
}

@media screen and (max-width: 375px) {
    html {
        font-size: 8px;
    }
}

@media screen and (max-width: 360px) {
    html {
        font-size: 7.68px;
    }
}

@media screen and (max-width: 320px) {
    html {
        font-size: 6.83px;
    }
}