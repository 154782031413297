html,body{
  height: 100%;
  width: 100%;
}
.swiper-slide img{
  width:100%;
}
#root{
  height: 100%;
}
.site-loading{
  position: fixed;
  width:100%;
  height:100%;
}
body .ant-drawer{
  z-index: 1002;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont,"微软雅黑", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.list-containe{
  background:#fff; 
  padding: 30px
}
.list-containe .list-header{
  font-size: 24px;
  border-left: 3px solid #2C7B93;
  padding-left: 30px
}
.list-search{
  padding:10px;
}
.list-header.no-search{
  margin-bottom:30px;
}
.list-containe .search-box{
  width:640px;
}
.login-container {
  height: 300px;
  background: #fff;
  padding:10px 20px;
}

.login-item{
  height: 25px;
  fontSize: 20px;
  lineHeight: 25px;
  textAlign: left;
  width: 400px;
}

.find-pass-container{
  background:#fff;
  top:calc(50% - 226px);
  height: 160px;
}

.register-container{
  width:100%;;
  background:#fff;
  padding:35px 30px;
}
.register-container .ant-form a,
.register-container .ant-form label{
  font-size: 18px;
}
.register-container form input[type='radio'], 
.register-container form input[type='checkbox'],
.register-container .ant-checkbox-inner{
  width:20px;
  height:20px;
}
.register-container .ant-checkbox-inner::after{
width:6px;
height:10px;
}
.time-end,
.time-start{
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 30px;
  white-space: nowrap;
}
.video-container{
  height: calc(100% - 230px);
  width: calc(100% - 80px);
  position: absolute
}
.speed-chart-container{
  background: #fff;
  position: absolute;
  width:calc(100% - 80px);
  bottom:0px;
}
.networkstate-chart-container{
  background: #fff;
  position: absolute;
  width:calc(100% - 80px);
  bottom:-200px
}
.rssi-chart-container{
  background: #fff;
  position: absolute;
  width:calc(100% - 80px);
  bottom:-400px
}
.plmn-chart-container{
  background: #fff;
  position: absolute;
  width:calc(100% - 80px);
  bottom:-600px
}

.map-buttons{
  position: absolute;
  left:0;
  bottom:0;
  z-index: 1000;
  padding:30px 20px 10px 20px;
  width:100%;
  text-align: center;
  background: rgba(255,255,255,0.5);
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.login-header{
  background: #166a8c;
  height: 80px;
  position: fixed;
  left: 0;
  right:0;
  top:0;
}
.login-bg{
  position: fixed;
  width:100%;
  top:80px;
  left:0;
  right:0;
  height: calc(100% - 80px);
}

.login-dropdown-list{
  position: absolute;
  right:0px;
  top:80px;
  z-index: 1010;
  background: #fff;
  color:#606266;
  width:146px;
  background-color: rgba(44,123,147,1);
  color: #fff;
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}


.login-dropdown-list ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.login-dropdown-list ul li{
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  font-size: 14px;
  margin: auto;
  cursor: pointer;
}
.login-dropdown-list ul li:first-child{
  border-bottom: 1px solid #606266;
}


.ant-modal-wrap.register-mask{
  top:110px;
  z-index: 100;
}

.login-form .login-title{
  font-size: 24px;
  color:#166a8c;
  margin-bottom: 0px;
}

.login-form .ant-input{
  padding-left: 10px;
  font-size: 20px;
}

.login-item-title{
  height: 25px;
  fontSize: 24px;
  lineHeight: 25px;
  textAlign: left;
  width: 100%;
}

.login-form input:-internal-autofill-previewed, 
.login-form input:-internal-autofill-selected, 
.login-form textarea:-internal-autofill-previewed, 
.login-form textarea:-internal-autofill-selected, 
.login-form select:-internal-autofill-previewed, 
.login-form select:-internal-autofill-selected{
  background-color: #fff !important;
  box-shadow: none;
}

.login-form .ant-input:hover,
.login-form .ant-input:active,
.login-form .ant-input:focus{
  border-color:#d2d2d2;
  box-shadow:none;
}


.login-form .login-form-button{
  background: #166a8c;
  width:100%;
  font-size: 20px;
  color:#fff;
}

.login-form .login-form-button:active,
.login-form .login-form-button:hover,
.login-form .login-form-button:focus{
  background: #166a8c;
  color:#fff;
  box-shadow: none;
}

.login-form .login-form-button_remembered{
  background: #166a8c;
  width:50%;
  font-size: 20px;
  color:#fff;
}

.login-form .login-form-button_remembered:active,
.login-form .login-form-button_remembered:hover,
.login-form .login-form-button_remembered:focus{
  background: #166a8c;
  color:#fff;
  box-shadow: none;
}

.login-form .forgot-pass{

  margin-top: 15px;
  float:right;
  font-size:20px;
  color:#166a8c;
  cursor: pointer;
}

/* login header */
.login-header-content{
  margin: auto;
}
.login-header-content .logo{
  margin-left: 45px;
  margin-top: 10px;
}

.login-header-content .right-part{
  float: right;
  font-size: 25px;
  line-height: 80px;
}

.login-header-content .right-part a,
.login-header-content .right-part span{
  display: inline-block;
  color:#fff;
}
.login-header-content .right-part .separate{
  width:1px;
  background:#fff;
  margin-left: 23px;
  margin-right: 23px;
  height: 28px;
  float: right;
  margin-top: 32px;
}

.login-header-content .right-part .right-dropdown{
  display: inline-block;
  position: relative;
  margin-left: 45px;
  color:#fff;
  padding-left: 48px;
  padding-right: 42px;
  background-image: url('images/lan-icon.png');
  background-repeat: no-repeat;
  background-position: left center;
  cursor: pointer;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #606266 !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.dropdown-list{
  position: absolute;
  left:0px;
  top:91px;
  z-index: 1010;
  background: #fff;
  color:#606266;
  width:146px;
}
.dropdown-list ul{
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.dropdown-list ul li{
  height: 40px;
  line-height: 40px;
  padding-left: 30px;
  font-size: 20px;
}
.dropdown-list ul li:first-child{
  border-bottom: 1px solid #606266;
}



/* left part */
.header-logo{
  text-align: center
}
.header-logo,
.gpsbd .ant-layout-header{
  height:80px;
  background: #166a8c;
}
.gpsbd .header-right{
  width:auto;
  height:80px;
  background:rgba(44,123,147,1);
  box-shadow:0px 0px 10px rgba(0,0,0,0.05);
  float: right;
  position: relative;
}
.header-right .dropdown-list{
  right:0px;
  left:initial;
  top:80px;
  background-color: rgba(44,123,147,1);
  color:#fff;
  border-top: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  z-index: 9999;
}
.header-right .dropdown-list ul li.has-bottom{
  border-bottom: 1px solid #dedede
}
.header-right .dropdown-list ul li{
  font-size: 14px;
  cursor: pointer;
}

.header-right .email-tag{
  min-width:83px;
  height: 80px;
  position: relative;
  margin-right: 20px;
  padding-right: 20px;
  float: left;
  line-height: 80px;
  background:#166a8c
}

.email-tag .email-icon-count{
  position: absolute;
  width:20px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  background: #EA2433;
  right:calc(50% - 5px);
  top:0px;
  overflow: hidden;
  text-align:center;
  color:#fff;
  font-size: 12px;
  z-index: 1;
}
.email-tag span.info{
  height: 40px;
  line-height: 40px;
  color: #fff;
  display: block;
  font-size: 20px;
  text-align: center;
}
.header-right .header-avatar{
  width: 46px;
  height:46px;
  line-height: 46px;
  overflow: hidden;
  border-radius: 23px;
  float: left;
  margin-top: 17px;
}
.header-right .header-avatar img{
  max-width:100%;
}

.header-right .header-login-user{
  padding-left: 10px;
  min-width: 42px;
  height: 80px;
  float: left;
  cursor: pointer;
}

.header-right .header-login-user span{
  display: block;
  height: 20px;
  color:#fff;
}
.header-right .header-drop-down{
  float: right;
  margin-right: 25px;
  margin-left: 5px;
  height: 80px;
  cursor: pointer;
  position: relative;
  z-index: 1001;
}
.header-drop-down img{
  position: absolute;
  top:20px;
  bottom:0;
  margin:auto;
}

.ant-form-explain{
  position: absolute;
  top:90px;
}


/* Menu部分 */

.first-level-menu > .ant-menu-submenu-title{
  height: 64px !important;
  line-height: 64px !important;
}

.gpsbd .ant-menu-vertical .ant-menu-item, 
.gpsbd .ant-menu-vertical-left .ant-menu-item, 
.gpsbd .ant-menu-vertical-right .ant-menu-item, 
.gpsbd .ant-menu-inline .ant-menu-item, 
.gpsbd .ant-menu-vertical .ant-menu-submenu-title, 
.gpsbd .ant-menu-vertical-left .ant-menu-submenu-title, 
.gpsbd .ant-menu-vertical-right .ant-menu-submenu-title,
.gpsbd .ant-menu-inline .ant-menu-submenu-title{
  font-size: 20px;
}

.gpsbd .ant-menu-item:focus,
.gpsbd .ant-menu-item:hover,
.gpsbd .ant-menu-item:active{
  background: none !important;
} 

.first-level-menu .ant-menu-item:focus,
.first-level-menu .ant-menu-item:hover,
.first-level-menu .ant-menu-item:active{
  background:#2C7B93 !important
}
.first-level-menu .ant-menu-item:focus > a,
.first-level-menu .ant-menu-item:hover > a,
.first-level-menu .ant-menu-item:active > a{
  color:#fff !important;
}
.ant-menu-item > a{
  color:#333333 !important;
}
.gpsbd .ant-menu-submenu-arrow{
  display: none;
}

.first-level-menu  .ant-menu-item:active, 
.first-level-menu .ant-menu-submenu-title:active{
  background:none;
}
.ant-menu-submenu-selected,
.ant-menu-item-selected,
.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
  color:#333333 !important
}
.first-level-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color:#2C7B93;
}
.first-level-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a{
  color:#fff !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after
{
  border-right:0 !important;
}

.icon_menu{
  padding-left: 40px;
}
.icon_sysuser{
  background: url('./images/sysuser_normal.png') no-repeat left center;
}

.first-level-menu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a.icon_sysuser{
  background-image: url('./images/sysuser.png')
}

/* Dashboard页面样式 */
.dashboard-container{
  background: #f5f5f5;
}
.dashboard-container .dashboard-top-summary{
  height: 120px;
  background: #fff;
  margin-bottom: 0px;
  padding-left: 57px;
}
.clearfix::after{
  clear: both;
  height: 1px;
  content: '';
  display: block;
}
.event-clip{
  float: left;
  width:16.66%;
  overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  -o-text-overflow:ellipsis;
  height: 100px;
}

.event-clip .img-container{
  width: 60px;
  overflow: hidden;
  float: left;
  background: #20BA56;
  border-radius: 30px;
  height: 60px;
  position: relative;
  margin-top: 30px;
}
.event-clip .img-container img{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 30px;
  height: 24px;
}

.event-clip .item-content{
  height: 70px;
  margin-top: 30px;
  padding-left: 80px;
  padding-right: 20px;
}
.event-clip .item-content .span-top{
    display: block;
    height: 42px;
    margin-bottom: 8px;
    font-size: 30px;
    color:#333333;
    font-weight:bold;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
    -o-text-overflow:ellipsis;
}
.event-clip .item-content .span-bottom{
  height: 20px;
  font-size: 14px;
}

.event-clip .bg-blue{
  background: #999999
}
.event-clip .bg-blue img{
  width: 19px;
  height: 26px;
}

.event-clip .bg-green{
  background: #D9A926
}

.event-clip .bg-green img{
  width: 22px;
  height: 26px;
}

.event-clip .bg-red{
  background: #EA2433
}

.event-clip .bg-red img{
  width: 28px;
  height: 28px;
}

.event-clip .bg-blue-2{
  background: #3A6ee3
}

.event-clip .bg-blue-2 img{
  width: 27px;
  height: 28px;
}

.event-clip .bg-yellow{
  background: #D9A926
}

.event-clip .bg-yellow img{
  width: 27px;
  height: 28px;
}

.event-clip .bg-purple{
  background: #B56BD6
}

.event-clip .bg-purple img{
  width: 30px;
  height: 26px;
}


/* 报表 */
.dashboard-report{
  margin-top: 10px;
  flex-direction: row;
  justify-content:space-between;
  display: flex;
}

.dashboard-report .report-item{
  width:calc(33.33% - 5px);
  background: #fff;
  height: 325px; 
}

.report-item .report-item-title{
  padding: 10px 20px;
  font-size: 18px;
  color:#fff;
  margin-bottom: 12px;
}
.report-item .item-yellow{
  background:#D9A926
}
.report-item .item-blue{
  background:#3A6ee3
}
.report-item .item-green{
  background:#20BA56
}
.report-item .report-item-info{
  padding:8px;
}
.report-item .report-item-info img{
  width: 100%;
}
.dashboard-map{
  height: 600px;
  margin-top: 10px;
}
.gpsbd .ant-btn-primary{
  background-color: #2C7B93;
  border-color:#2C7B93;
}

.gpsbd .ant-btn-primary:hover, 
.gpsbd .ant-btn-primary:focus
.gpsbd .ant-btn-primary:active, 
.gpsbd .ant-btn-primary.active{
  background-color: #2C7B93;
  border-color:#2C7B93;
}


/* Profile 样式 */
.profile-buttons{
  float: right;
}
.profile-buttons button+button{
  margin-left: 20px;
}


.ant-menu-dark.ant-menu-horizontal > .ant-menu-item, .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu{
  border-color: #166a8c !important;
}
.ant-menu-dark, .ant-menu-dark .ant-menu-sub{
  background:#166a8c !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected, .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected{
  background-color: rgba(44,123,147,1) !important;
  color:#fff !important;
  font-weight:bold;
}
.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a{
  color:#fff !important;
  font-size: 20px;
  font-weight: bold;
}
.ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu{
  font-weight: bold;
}
.ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span,
.ant-menu-item > a{
  font-size: 20px;
}
.gpsbd .ant-btn-primary,
.gpsbd .ant-btn-primary:hover, 
.gpsbd .ant-btn-primary:focus,
.gpsbd .ant-table-thead > tr > th{
  background:#2C7B93;
  color:#fff;
}
.gpsbd .ant-table{
  color:#000;
}
.gpsbd .ant-btn-group .ant-btn-primary:first-child:not(:last-child){
  border-right-color:#166a8c;
}

.gpsbd .ant-btn-group .ant-btn-primary:last-child:not(:first-child), 
.gpsbd .ant-btn-group .ant-btn-primary + .ant-btn-primary{
  border-left-color:#166a8c;
}

.process-bar{
  background: #ededed;
  position: absolute;
  flex: 1;
  left:0;
  top:5px;
  right:0;
  height: 5px;

}

.process-bar .process-bg{
  background:#2c7b93;
  height:100%;
  display: inline-block;
  position: absolute;
  left:0;
  top:0;
}
.process-bar .process-point{
  background: #2c7b93;
  width:15px;
  height:15px;
  border-radius: 7px;
  position: absolute;
  top:-4.5px;
  left:100%;
  z-index:9999;
}

.process-point:hover{
  cursor:pointer;
}

.action-common-box{
  width:30px;
  height:30px;
  border-radius: 3px;
  background:#2c7b93;
  position: relative;
  margin-left: 20px;
  cursor: pointer;
}
.action-common-box img{
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}
.action-common-box .stop-icon{
  width:12px;
  height:12px;
  background-color: #fff;
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
}
.trip-desc-title{
  font-size: 18px;
  font-weight: bold;
}
.trip-desc{
  line-height: 30px;
  height:130px;
  margin-bottom: 20px;
  background: #fff;
  padding:20px;
  font-size: 16px;
}

.tblNoHeader .ant-table-thead{
  display: none;
}

.tblNoHeader .row40{
  height: 40px;
}
.map-buttons button,
.row40 .ant-col{
  line-height: 40px;
  height: 40px;
}
.map-buttons button{
  background:#2c7b93;
  color:#fff;
}

.map-buttons .ant-btn:hover, 
.map-buttons .ant-btn:focus, 
.map-buttons .ant-btn:active, 
.map-buttons .ant-btn.active{
  background:#2c7b93;
  color:#fff;
}

.field-img-container
{
  height:42px;
  float:left;
  position: relative;
  margin-right: 10px;
}
.field-img-container img{
  width:28px;
  margin-right: 10px;
}
.field-content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.menu-icon{
  margin-right: 10px;
}

.gpsbd .fa{
  font:normal normal normal 20px/1 FontAwesome;
}

.top-menus .menu-icon{
  display: block;
  position: relative;
  height: 40px;
  margin-right: 0px;
  font-size: 35px;
  color:#fff;
}
.top-menus .menu-icon::before{
  position: absolute;
  left:calc(50% - 17.5px);
  top:calc(50% - 13px);
  margin:auto;
}
.top-menus .menu-icon.fa-home::before{
  top:3px
}

.change-language{
  background:url('images/lan-icon.png') no-repeat left center;
  background-size: 26px 26px;
  float: right;
  min-width: 90px;
  padding-left: 35px;
  padding-right: 10px;
  color:#fff;
  height:80px;
  line-height: 80px;
  cursor: pointer;
}
.pop-window-info{
  color:black;
  font-size:18px;
  line-height: 40px;
}
.btn-primary{
  background: #2c7b93;
  padding:5px 20px;
  border:1px solid #2c7b93;
  color:#fff;
  border-radius: 5px;
}

.btn-primary:hover{
  color:#fff;
}

@media print{
  .noprint{
    display: none;
  }
}

.back-link{
    color: rgba(0, 0, 0, 0.65);
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
}
.boldandbig{
  font-weight: bold;
}

.event-enable,
.geofence-enable{
  background-repeat: no-repeat;
  display:inline-block;
  margin: 12px 5px;
  float: left;
}

.event-icon,
.geofence-icon
{
  background-position:left center;
  background-repeat: no-repeat;
  background-size:35px 35px;
  padding: 2px 5px;
  display:inline-block;
  line-height:33px;
  height:40px;
  float: left;
}

.mileage-icon,
.mile-icon{
  background-position:left center;
  background-repeat: no-repeat;
  background-size:35px 35px;
  padding-left: 32px;
  display:inline-block;
  line-height:35px;
}

.mile-icon{
  background-image: url('./images/mile-icon.png');
  background-size:30px 30px;
}

.mileage-icon{
  background-image: url('./images/mileage.png');
  background-size:25px 25px;
  padding-right: 5px;
}
.event-icon{
  width:40px;
  height:40px;
}
.geofence-icon{
  width:40px;
  height:40px;
}
.fa-key{
  cursor: pointer;
}

.my-tree ul{
  list-style: none;
}

.my-tree ul li{
  line-height: 30px;
}
.gpsdb-mask{
  position: fixed;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1001;
}
.gpsdb-loading{
  position: fixed;
  left:calc(50% - 30px);
  top:calc(50% - 30px);
  width:60px;
  height:60px;
  text-align:center;
  z-index: 1002;
  background:#fff;
  border-radius: 5px;
}

.addon-select{
  width:120px;
}
.map-scale{
  position: absolute;
  left:calc(50% + 10px);
  width:50px;
  bottom:50px;
  z-index: 1001;
  background: rgba(255, 255, 255, 0.65);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  display: flex;
}
.slider-container{
  width:30px;
  height:120px;
  color:#000;
  margin-bottom:10px;
}
.text-right{
  text-align: right;
}
.slider-input{
  margin-right: 15px;
  margin-left: 15px;
}
.slider-input input{
  height: 40px;
}
.gpsbd .ant-slider-step{
  background:grey;
}
.gpsbd .bg-red{
  background-color: red;
}
.gpsbd .bg-grey{
  background-color: #dedede;
}
.gpsbd .bg-yellow{
  background-color: #FFA500;
}
.gpsbd .customizeBg{
  padding:0px;
}
.gpsbd th.customizeBg{
  padding:16px;
}
.gpsbd .item-bold{
  font-weight: bold;
}
.gpsbd .msg_bg_1{
  background: blue;
}
.gpsbd .msg_bg_2{
  background: orange;
}
.gpsbd .msg_bg_3{
  background: red;
}

.desc-item{
  display: inline;
}

.desc-item-text{
  color:#000
}
.desc-item-block{
  width:12px;
  height: 12px;
  display: inline-block;
  background: red;
  border:1px solid #000;
}
.desc-item-orange{
  width:12px;
  height: 12px;
  display: inline-block;
  background:orange;
  margin-right: 5px;
  border:1px solid #000;
}
.desc-item-white{
  width:12px;
  height: 12px;
  display: inline-block;
  background: white;
  border:1px solid #000;
}
.desc-item:last-child{
    margin-left: 0px;
}
.area_container{
  position: relative;
}
.area{
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body .ant-notification{
  margin-right: 0px;
  width:auto;
}
body .anticon.ant-notification-notice-icon-info{
  display: none;
}
body .ant-notification-notice-with-icon .ant-notification-notice-message{
  margin-left: 10px;
  color:#fff;
font-size: 14px;
margin-top: 5px;
margin-bottom: 5px;
}
body .ant-notification-notice-close{
  top:6px;
  color:#fff;
}
body .ant-notification-notice{
  border-radius: 0;
  padding:0px;
  margin-bottom: 0px;
}
body .ant-notification-notice-with-icon{
  background-color: #2C7B93;
}
body .ant-notification-notice-with-icon .ant-notification-notice-description
{
  margin-left: 0px;
  background-color: #fff;
  padding:10px;
  min-height: 100px;
  min-width: 300px;
}
.ant-form-item.column_left{
  display: inline-block;
}
.ant-form-item.column_left_2{
  display: inline-block;
  margin-left: 30px;
}
.drawControl{
  position: absolute;
  left:calc(50% - 40px);

  top:0px;
  height:40px;
  padding-left: 10px;
  padding-top:0px;
  padding-right:5px;
  padding-bottom:5px;
  background:#fff;
  box-sizing: border-box;
}
.drawControl .drawControl_tag{
  width: 32px;
  height:32px;
  float: left;
  margin-right:5px;
}

.replay_geofence{
  position: absolute;
  left:10px;
  bottom:122px;
  z-index: 1999;
  background: #fff;
  width:52px;
  height:52px;
  padding:10px;
  border:1px solid #dedede;
  opacity: 0.7;
}

.replay_event{
  position: absolute;
  left:62px;
  bottom:70px;
  z-index: 1999;
  background: #fff;
  width:52px;
  height:52px;
  padding:10px;
  border:1px solid #dedede;
  opacity: 0.7;
}

.back_to_location{
  position: absolute;
  left:10px;
  bottom:70px;
  z-index: 1999;
  background: #fff;
  width:52px;
  height:52px;
  padding:10px;
  border:1px solid #dedede;
  opacity: 0.7;
}
.icon-list{
  display: flex !important;
  justify-content: flex-end;
}
.icon-container{
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icon-container i{
  font-size: 25px !important;
}
.icon-container i.fa-mobile{
  margin-left: 10px;;
}
.icon-container-big{
  width:60px;
}


.row-start{
  background-image:url(./images/start-icon.png);
  background-position: left center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  padding-left: 50px;
}

.row-end{
  background-image:url(./images/end-icon.png);
  background-position: right center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  padding-right: 50px;
}

.tripvideo-startTime{
  position: absolute;
  top:25px;
  left: 0;
  z-index: 100;
  background-color:rgba(255,255,255,0);
  opacity: 0.8;
  color:black;
  font-weight: bold;
}

.tripvideo-endTime{
  position: absolute;
  top:25px;
  right: 0;
  background-color:rgba(255,255,255,0);
  text-align: right;
  z-index: 1001;
  color:black;
  font-weight: bold;
}
.tripvideo-drivingTime{
  position: absolute;
  left:20px;
  bottom: 0;
  height:60px;
  background-color:rgba(255,255,255,0);
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color:black;
  font-weight: bold;
}
.tripvideo-mile{
  position: absolute;
  right:20px;
  bottom: 0;
  height:60px;
  background-color:rgba(255,255,255,0);
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color:black;
  font-weight: bold;
}

.tripvideo-speedchart{
  width:100%;
  margin:25%;
  display:inline;
}

a[rel='noopener'] img,.gm-style-cc{
  display: none;
}

.tripvideo-scale{
  position: absolute;
  top:60px;
  left:10px;
  z-index: 1001;
  width: 40px;
  height: 80px;
  text-align: center;
  background: #fff;
  border:1px solid #dedede;
  border-radius: 3px;
  box-shadow: 0px 0px 5px 0px #ddd;
}
.tripvideo-scale i{
  display: block;
  font-size: 20px !important;
  margin-top:10px;
}
.tripvideo-scale i.fa-plus{
  border-bottom: 1px solid #dedede;
  padding-bottom:10px;
}

.navigate-bar{
  position: absolute;
  left:40;
  top:0;
  height:40px;
  width:calc(100% -200px);
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.navigation-bar-item a{
  color:#000;
  margin-right: 5px;
}
.navigation-bar-item a:hover{
  color:#2C7B93
}
.navigation-bar-item span{
  display: inline-block;
  margin-right: 5px;
}
.steps-content,
.steps-action{
  margin-top: 30px;
}
.steps-action{
  text-align: center;
  padding-bottom: 30px;
}
.list-row-title{
  background-color:#dedede;
  border-radius:5px;
  padding:10px 20px;
  margin-top:20px;
  margin-bottom:20px
}
.scrollable-view .ant-table-body{
  overflow-y:scroll;
}
.s-version{
  position: absolute;
  right: 20px;
  bottom: 20px;
  color:#fff
}
.map-selector{
  position: absolute;
  z-index: 1000;
  height: 25px;
  line-height: 25px;
  width:100px;
  background: #fff;
}
.map-selector-title{
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.map-center{
  left: calc(50% - 50px);
  top:0;
}
.map-selector .map-selector-list{
  display: none;
  position: absolute;
  left: 0;
  top:25px;
  background: #fff;
  margin:0px;
  padding:0px;
  list-style: none;
  width:100px;
  text-align:center;
}
.map-selector .map-selector-list li{
  border-top: 1px solid #dcdcdc;
  cursor: pointer;
}

.show_more{
  text-align:right;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}

.version-info{
  width:100%;
  margin:auto;
  font-size: 18px;;
  padding:20px;
  border-radius: 10px;
  margin-top: 30px;
}
.version-row{
  padding:20px 0px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.row-content{
  flex-direction: column;
  justify-content: flex-start;
}
.version-row:last-child{
  border-bottom: 0px;
}
.version-row label{
  font-weight: bold;
  display: inline-block;
  font-size:28px;
  width:30%;
}

.trip-data-info{
  width:100%;
  height: 35px;
  background: #fff;
  margin-top: 0px;
  box-sizing: border-box;
  padding:0px;
}
.trip-data-info .trip-data{
  border:1px solid #000000;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 0px;
  padding-right: 5px;
}
.trip-data-right{
  align-items: center;
  justify-content: flex-end;
  display: flex;
  flex:1
}
.trip-data-left{
  flex:1
}
.canvas-container{
  padding-top: 0px;
}

#root .ant-input-affix-wrapper::before{
  content: '';
}

#root .ant-input{
  height: auto;
  width: 100%;
}

#root .ant-col{
  width: 100%;
}
